import React, { useState } from "react";
import Container from "../Container";
import { CloseIcon, MenuIcon } from "../Icons";

export default function NavBar() {
  const [show, setShow] = useState(false);

  const toggleMenu = () => setShow(!show);

  console.log(show);
  return (
    <Container className="flex gap-20 justify-between md:justify-start items-center h-28 relative z-40">
      <img className="md:h-32 h-24" src="/assets/logo.png" alt="Logo" />
      <ul className="md:flex hidden  flex-1 justify-center gap-10 items-center ">
        <NavLink path="/#HOME" name="Home" />
        <NavLink path="/#ECOSYSTEM" name="Ecosystem" />
        <NavLink path="/#NFTS" name="Our NFTs" />
        <NavLink path="/#ROADMAP" name="Roadmap" />
        {/* <NavLink path="/#TEAM" name="Our Team" /> */}
      </ul>
      <button onClick={toggleMenu} className="md:hidden text-white text-2xl">
        <MenuIcon />
      </button>
      {show && (
        <div className="w-screen h-screen fixed inset-0 bg-background z-50 px-4">
          <div className="justify-end flex  h-28">
            <button
              onClick={toggleMenu}
              className="md:hidden text-white text-2xl"
            >
              <CloseIcon />
            </button>
          </div>

          <ul className="flex-col flex gap-6">
            <NavLink path="/#HOME" name="Home" />
            <NavLink path="/#ECOSYSTEM" name="Ecosystem" />
            <NavLink path="/#NFTS" name="Our NFTs" />
            <NavLink path="/#ROADMAP" name="Roadmap" />
            {/* <NavLink path="/#TEAM" name="Our Team" /> */}
          </ul>
        </div>
      )}
    </Container>
  );
}

export const NavLink = ({ name, path }) => {
  return (
    <li className="text-white hover:text-app-primary cursor-pointer">
      <a href={path}>{name}</a>
    </li>
  );
};
