import React from "react";
import Container from "../Container";
import { ColumnIcon, ShareIcon, TaxIcon } from "../Icons";
import Heading, { Gradient } from "../Typography/Heading";
import Text from "../Typography/Text";

export default function EcoSystem() {
  return (
    <Container id="ECOSYSTEM" className="my-40">
      <div className="text-center space-y-4">
        <Heading className="text-6xl">
          The <Gradient>Footbull Ecosystem</Gradient>
        </Heading>
        <Text className="max-w-2xl mx-auto">
        The footbull ecosystem has been flawlessly designed so that each segment of the ecosystem benefits the other.</Text>
        <Text className="max-w-2xl mx-auto">
        All volume accrued from Uniswap V3 goes to our prize pool, which means throughout volume and activity we will attract more & more players with our large and versatile prize pools. NFTs need to be minted to play, and the fees from minting those NFTs are bought back into the token and burnt, making it more scarce. 
        </Text>
      </div>
      <div className="flex gap-10 mt-48 flex-col gap-y-20 md:flex-row">
        <Card icon={<TaxIcon />}>Volume allocated to prizes</Card>
        <Card icon={<ShareIcon />}>NFTs minted to play</Card>
        <Card icon={<ColumnIcon />}>Buyback into $FBull from NFT minting</Card>
      </div>
    </Container>
  );
}

const Card = ({ children, icon}) => {
    const Icon = () => icon
  return (
    <div className="relative p-6  bg-[#272534] flex md:w-max w-full pt-24 transform md:even:-translate-y-1/2 rounded-xl flex-1 justify-center items-center">
        <div className="w-20 absolute top-0 left-1/2 h-20 flex text-sm justify-center items-center bg-gradient-to-br -translate-x-1/2 rounded-xl -translate-y-1/2 from-app-primary to-app-secondary">
            {icon && <Icon />}
        </div>
      <p className="text-white text-xl text-center">{children}</p>
    </div>
  );
};
